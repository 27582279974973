import * as React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "@react-oauth/google";
import { Link } from "../Link";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useTatari } from "../../analytics/useTatari";
import { IUser } from "@fitplan/context/lib-es/auth/types";

import { SignUpStrings, SignUpController } from "./SignUpController";
import {
  Row,
  SeparatorRow,
  Separator,
  SeparatorText,
  ErrorRow,
  TermsRow,
  ResponsiveInput,
  FormRow,
  RadioGroup,
  InputRow,
  SignUpContainer,
  SignUpRow,
  AppleSignInContainer,
} from "./layout";
import { SSOButton, LoginButton } from "./Button";
import { GoogleIcon, FacebookIcon } from "./icons";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

export interface Props {
  disabled?: boolean;
  darkMode?: boolean;
  partner?: string;
  onComplete: (user: IUser) => void;
  redirectUri?: string;
}

export const SignUpForm: React.SFC<Props> = ({
  redirectUri,
  disabled,
  darkMode,
  onComplete,
  partner,
}) => {
  const config = useConfig();
  const { tatariIdentify, tatariTrack } = useTatari();
  const { t } = useTranslation();
  const { language } = useI18next();
  const [isoLang, setIsoLang] = React.useState('en-US');

  React.useEffect(() => {
    if (language) {
      switch (language) {
        case 'en':
        default:
          setIsoLang('en-US');
          break;
        case 'es':
          setIsoLang('es-ES');
          break;
      }
    }

    return () => {
      setIsoLang('en-US');
    };
  }, [language]);

  return (
    <SignUpController
      partner={partner}
      onComplete={(user) => {
        tatariIdentify(user.userId);
        tatariTrack("signup", { userId: user.userId });
        onComplete(user);
      }}
      redirectUri={redirectUri}
    >
      {({
        globalError,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        gender,
        onSubmit,
        isLoading,
        submitDisabled,
        facebookSignUp,
        googleSignUp,
        emailCheckbox,
      }) => (
        <SignUpContainer darkMode={darkMode} onSubmit={onSubmit}>
          <Row>
            <FacebookLogin
              appId={config.facebookAppId}
              autoLoad={false}
              disableMobileRedirect={true}
              fields="email"
              authType={"reauthenticate"}
              render={(innerProps: any) => (
                <SSOButton
                  colors="dark"
                  icon={<FacebookIcon />}
                  onClick={innerProps.onClick}
                  disabled={disabled}
                >
                  {t("facebookSignupButton")}
                </SSOButton>
              )}
              {...facebookSignUp}
            />
            <div className="google-login-container">
              <GoogleLogin
                shape="rectangular"
                logo_alignment="left"
                {...googleSignUp}
                text="signup_with"
                theme="filled_blue"
                /* @ts-ignore */
                width={320}
                locale={isoLang}
              />
            </div>
            <AppleSignInContainer
              id="appleid-signin"
              data-color="black"
              data-border="true"
              data-type="sign in"
            />
          </Row>
          <SeparatorRow>
            <Separator />
            <SeparatorText>{t("signupWithEmail")}</SeparatorText>
            <Separator />
          </SeparatorRow>
          <InputRow>
            <ResponsiveInput
              darkMode={darkMode}
              mobileOrder={1}
              order={1}
              name="firstName"
              type="text"
              {...firstName}
            />
            <ResponsiveInput
              darkMode={darkMode}
              mobileOrder={2}
              order={2}
              name="lastName"
              type="text"
              {...lastName}
            />
            <ResponsiveInput
              darkMode={darkMode}
              mobileOrder={4}
              order={4}
              name="username"
              type="email"
              {...email}
            />
            <FormRow mobileOrder={5} order={5}>
              <RadioGroup>
                <input type="radio" {...gender.male} />
                <label>{t("radioMaleText")}</label>
              </RadioGroup>
              <RadioGroup>
                <input type="radio" {...gender.female} />
                <label>{t("radioFemaleText")}</label>
              </RadioGroup>
              <RadioGroup>
                <input type="radio" {...gender.other} />
                <label>{t("radioOtherText")}</label>
              </RadioGroup>
            </FormRow>

            <ResponsiveInput
              darkMode={darkMode}
              mobileOrder={6}
              order={6}
              name="password"
              type="password"
              {...password}
            />

            <ResponsiveInput
              darkMode={darkMode}
              mobileOrder={7}
              order={7}
              name="confirmPassword"
              type="password"
              {...confirmPassword}
            />
          </InputRow>
          <ErrorRow>{globalError}</ErrorRow>
          <LoginButton
            disabled={submitDisabled}
            type="submit"
            onClick={onSubmit}
          >
            {isLoading ? "..." : t("singUpButtonText")}
          </LoginButton>
          <TermsRow>
            {t("accountAgreement")}
            <Link to="/terms-of-service">{t("termsOfService")}</Link>
            &nbsp;&nbsp;{t("accountAgreementAnd")}
            <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
          </TermsRow>
        </SignUpContainer>
      )}
    </SignUpController>
  );
};

export type SignUpFormStrings = {
  facebookButton: string;
  googleButton: string;
  loginButton: string;
  spearatorText: string;
  title: string;
  footerText: string;
  loginLinkText: string;
  emailCheckbox: string;
  termsOfService: string;
  privacyPolicy: string;
  accountAgreement: string;
} & SignUpStrings;

export const SignUpFooter: React.FunctionComponent<{
  onClick?: () => void;
  darkMode?: boolean;
  link?: string;
}> = ({ onClick, link = "/login", darkMode }) => {
  const { t } = useTranslation();
  return (
    <SignUpRow darkMode={darkMode}>
      {t("footerText")}
      <Link
        to={link}
        onClick={(event) => {
          if (onClick) {
            event.preventDefault();
            onClick();
          }
        }}
      >
        {t("loginLinkText")}
      </Link>
    </SignUpRow>
  );
};
