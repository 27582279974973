import styled from "@emotion/styled";

import { Input } from "../Input";

export const tabletBreakpoint = "500px";
export const desktopBreakpoint = "800px";

export const Container = styled.form<{ darkMode?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75em;
  box-sizing: border-box;
  max-width: 25em;
  margin: 0 auto;

  color: ${(props) => (props.darkMode ? "white" : "black")};
  --terms-text-color: ${({ darkMode }) => (darkMode ? "#ffffff" : "#000000")};
  --form-text-color: ${({ darkMode }) => (darkMode ? "#ffffff" : "#000000")};
`;

export const SignUpContainer = styled(Container)`
  max-width: 40em;
`;

export const Row = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  margin-top: 2em;
  font-family: "Lato";
`;

export const InputRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  font-family: "Lato";
`;

export const ErrorRow = styled(Row)`
  margin: 0.5em 0;
  color: #fc172f;
  font-size: 0.9em;
  font-family: "Lato";
`;

export const SignUpRow = styled(Row)<{ darkMode?: boolean }>`
  margin: 0.5em 0;
  font-family: "Barlow Condensed";
  font-size: 1.1em;
  line-height: 1.3;
  font-family: "Lato";
  text-align: center;
  color: ${(props) => (props.darkMode ? "white" : "black")};

  a {
    margin-left: 0.5em;
    color: #29db57;

    :hover,
    :active,
    :focus {
      color: #12b587;
    }
  }
`;

export const ForgotPasswordRow = styled(SignUpRow)`
  margin: 1em 0 0.1em 0;
  font-family: "Lato";
`;

export const TermsRow = styled(SignUpRow)`
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--terms-text-color);

  a {
    font-weight: bold;
    color: var(--terms-text-color);

    :hover,
    :active,
    :focus {
      color: #12b587;
    }
  }
`;

export const SeparatorRow = styled(Row)`
  display: flex;
`;

export const Separator = styled.div`
  flex: 1 0 2em;

  align-self: center;
  width: 100%;
  box-sizing: border-box;
  height: 2px;
  border: solid 1px #ccc;
  margin: 0 1em;
`;

export const SeparatorText = styled.div`
  flex: 0 0 auto;

  font-family: "Lato";
  font-size: 1em;
  line-height: 1;
  align-self: center;
`;

interface InputProps {
  mobileOrder?: number;
  order?: number;
}

export const ResponsiveInput = styled(Input)<InputProps>`
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  flex: 0 0 100%;
  margin-bottom: 18px;
  font-family: "Lato";
  order: ${(props) => props.mobileOrder};

  @media (min-width: ${tabletBreakpoint}) {
    order: ${(props) => props.order};
    flex: 0 0 100%;
  }
`;

export const FormRow = styled.div<InputProps>`
  display: inline-block;
  box-sizing: border-box;
  flex: 0 0 100%;
  margin: 15px 0 10px 0;
  order: ${(props) => props.mobileOrder};

  @media (min-width: ${tabletBreakpoint}) {
    order: ${(props) => props.order};
    flex: 0 0 100%;
  }
`;

export const RadioGroup = styled.span`
  font-family: "Lato";
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: var(--form-text-color);

  label {
    padding-right: 16px;
  }

  input {
    /* remove standard background appearance */
    appearance: none;
    border: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 16px;
    height: 16px;
    background-clip: content-box;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 8px;

    &:checked {
      padding: 3px;
      box-shadow: none;
      border: 5px solid #12b587;
    }
  }
`;

export const Title = styled(Row)`
  font-family: Barlow, sans-serif;
  font-size: 2.5em;
  font-weight: bold;
  font-stretch: condensed;
  text-align: center;
  margin: 0;
`;

export const AppleSignInContainer = styled.div`
  width: 100%;
  height: 3em;
  min-width: 15em;
  max-width: 20em;
  overflow: hidden;
  border-radius: 5px;
  margin: 0.5em auto;

  &:hover {
    cursor: pointer;
  }
`;

export { Input };
