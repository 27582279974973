import * as React from "react";
import useSetState from "react-use/lib/useSetState";
import { CredentialResponse } from "@react-oauth/google";
import { FitplanError } from "@fitplan/context/lib-es/error";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { loginSucceeded, IUser } from "@fitplan/context/lib-es/auth/types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Field, validateEmail } from "./common";
import { useAppleSSO } from "./useAppleSSO";

export interface Props {
  onComplete: (user: IUser) => void;
  children: (input: LogInState) => JSX.Element;
  redirectUri?: string;
}

export interface LoginStrings {
  emailPlaceholder: string;
  passwordPlaceholder: string;
  unknownError: string;
  facebookError: string;
  googleError: string;
  loginError: string;
  passwordError: string;
  emailError: string;
  facebookButton: string;
  googleButton: string;
  loginButton: string;
  spearatorText: string;
  title: string;
  footerText: string;
  signUpLinkText: string;
  forgotPasswordLinkText: string;
}

export const LogInController: React.FunctionComponent<Props> = (props) => {
  const [state, setState] = useSetState<State>({
    email: null,
    password: null,
    isLoading: false,
    error: null,
    submitDisabled: true,
    googleDisabled: false,
  });
  const { login, facebookLogin, googleLogin } = useAuthContext();
  const { t } = useTranslation();
  const strings = {
    facebookButton: "Sign Up with Facebook",
    googleButton: "Sign Up with Google",
    firstNamePlaceholder: "First Name",
    lastNamePlaceholder: "Last Name",
    emailPlaceholder: t("email"),
    passwordPlaceholder: t("password"),
    repeatPasswordPlaceholder: "Repeat Password",
    radioMaleText: "Male",
    radioFemaleText: "Female",
    radioOtherText: "Other",
    spearatorText: "or sign up with email",
    title: "Create Your Account",
    footerText: "Already have an account?",
    dontHaveAnAccount: "Don't have an account?",
    loginLinkText: "Login",
    loginButton: "Sign Up",
    unknownError: "An unknown error occured. Please try again later.",
    facebookError:
      "An error occurred with Facebook sign up. Please try again later.",
    googleError:
      "An error occurred with Google sign up. Please try again later.",
    signUpError: "Email is already signed up",
    emailCheckbox: "Receive Fitplan updates and promotions by email",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    accountAgreement: "By creating your account, you agree to Fitplan's",
    emailError: "Email Error",
    passwordError: "Password Error",
  };

  const googleCallback = async (response: any) => {
    setState({ isLoading: true });
    if (!response || !response.credential) {
      // TODO: Add sentry
      setState({
        error: strings.googleError,
        isLoading: false,
      });
      return;
    }
    const result = await googleLogin((response as any).credential as string);
    if (loginSucceeded(result)) {
      setState({ isLoading: false });
      props.onComplete(result);
    } else {
      setState({ isLoading: false, error: result.message });
    }
  };

  const googleErrorCallback = (response: {
    details: string;
    error: string;
  }) => {
    if (
      response?.error === "popup_closed_by_user" ||
      response?.error === "access_denied"
    ) {
      return;
    } else if (response?.error === "idpiframe_initialization_failed") {
      setState({
        error: "",
        googleDisabled: true,
        isLoading: false,
      });
    } else {
      // TODO: Add sentry
      setState({
        error: strings.googleError,
        isLoading: false,
      });
    }
  };

  const facebookCallback = async (user: any) => {
    setState({ isLoading: true });
    if (!user || !user.accessToken) {
      // TODO: Add sentry
      setState({
        error: user.status !== "not_authorized" ? strings.facebookError : "",
        isLoading: false,
      });
      return;
    }
    const result = await facebookLogin(user.accessToken);
    if (loginSucceeded(result)) {
      setState({ isLoading: false });
      props.onComplete(result);
    } else {
      setState({ isLoading: false, error: result.message });
    }
  };

  const onChange = (
    name: string
  ): ((event: React.SyntheticEvent<HTMLInputElement>) => void) => {
    return (event: React.SyntheticEvent<HTMLInputElement>) => {
      const input = event.target as HTMLInputElement;
      const newValue = input.value;
      setState({ [name]: newValue });
    };
  };

  const onFocus = (name: string): (() => void) => {
    return () => {};
  };

  const onBlur = (name: string): (() => void) => {
    return () => {
      setState({ error: validateField(name, state) });
    };
  };

  const validateField = (name: string, state: State): string | null => {
    switch (name) {
      case "email":
        if (state.email && !validateEmail(state.email)) {
          return strings.emailError;
        }
        break;
      case "password":
        if (state.password && state.password.length < 6) {
          return strings.passwordError;
        }
        break;
    }
    return null;
  };

  const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();

    const error =
      validateField("email", state) || validateField("password", state);

    if (!error) {
      setState({ isLoading: true });
      const result = await login(state.email || "", state.password || "");
      if (loginSucceeded(result)) {
        setState({ isLoading: false });
        props.onComplete(result);
      } else {
        setState({ isLoading: false, error: result.message });
      }
    } else {
      setState({ error });
    }
  };

  const controller = React.useMemo<LogInState>(() => {
    const emailValid = validateField("email", state);
    const passwordValid = validateField("password", state);

    return {
      email: {
        value: state.email || "",
        onChange: onChange("email"),
        onBlur: onBlur("email"),
        onFocus: onFocus("email"),
        placeholder: strings.emailPlaceholder,
        invalid: !!(state.email && !emailValid),
      },
      password: {
        value: state.password || "",
        onChange: onChange("password"),
        onBlur: onBlur("password"),
        onFocus: onFocus("password"),
        placeholder: strings.passwordPlaceholder,
        invalid: !!(state.password && !passwordValid),
      },
      onSubmit: onSubmit,
      globalError: state.error,
      isLoading: state.isLoading,
      submitDisabled: state.isLoading || !!(emailValid || passwordValid),
      facebookLogin: {
        callback: facebookCallback,
      },
      googleLogin: {
        onSuccess: googleCallback,
        onError: googleErrorCallback,
        disabled: state.googleDisabled,
      },
    };
  }, [strings, state]);

  useAppleSSO({
    redirectUri: props.redirectUri,
    onComplete: props.onComplete,
    onError: (err) =>
      setState({
        error:
          typeof err === "string"
            ? `Apple Sign In Error: ${err}`
            : "Unknwon Apple Sign In error. Please try again later.",
      }),
  });

  return props.children(controller);
};

export interface LogInState {
  email: Field;
  password: Field;
  onSubmit: (event: React.SyntheticEvent) => void;
  globalError: string | null;
  isLoading: boolean;
  submitDisabled: boolean;
  facebookLogin: {
    callback: (user: any) => void;
  };
  googleLogin: {
    onSuccess: (
      response: CredentialResponse
    ) => void;
    onError: () => void;
    disabled: boolean;
  };
}

interface State {
  email: string | null;
  password: string | null;
  error: string | null;
  isLoading: boolean;
  submitDisabled: boolean;
  googleDisabled: boolean;
}
