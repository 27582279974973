import axios from "axios";
import useMount from "react-use/lib/useMount";

import { useConfig } from "@fitplan/context/lib-es/config";

import { appleSignInClientId, appleSignInRedirectUri } from "../../../config";
import { IUser } from "@fitplan/context/lib-es/auth/types";
import { useAuthContext } from "@fitplan/context/lib-es/auth";

export const useAppleSSO = (props: {
  redirectUri?: string;
  onComplete: (user: IUser) => void;
  onError: (err: string) => void;
}) => {
  const { apiBaseUrl, fitplanApiLoginClientId } = useConfig();
  const { setAccessToken } = useAuthContext();

  useMount(() => {
    (window as any).AppleID.auth.init({
      clientId: appleSignInClientId,
      scope: "name email",
      redirectURI: appleSignInRedirectUri,
      state: props.redirectUri || window.location.toString(),
      usePopup: true, //or false defaults to false
    });
    const onSuccess = async (event: CustomEvent) => {
      if (
        event.detail &&
        event.detail.authorization &&
        event.detail.authorization.id_token
      ) {
        try {
          const result = await axios.post<{
            result?: { access_token: string };
            error?: string;
          }>(`${apiBaseUrl}/v1/auth/apple/signin?source=web`, {
            clientId: fitplanApiLoginClientId,
            identityToken: btoa(event.detail.authorization.id_token),
          });
          const accessToken = result?.data?.result?.access_token;
          if (accessToken) {
            const user = await setAccessToken(accessToken);
            props.onComplete(user);
          } else {
            props.onError(result.data.error);
          }
        } catch (err) {
          props.onError(err.toString());
        }
      }
    };
    const onError = (event: CustomEvent) => {
      if (event.detail && event.detail.error === "popup_closed_by_user") {
        return;
      }
      props.onError("Apple Sign in error");
    };
    //Listen for authorization success
    document.addEventListener("AppleIDSignInOnSuccess", onSuccess);
    //Listen for authorization failures
    document.addEventListener("AppleIDSignInOnFailure", onError);
    return () => {
      window.removeEventListener("AppleIDSignInOnSuccess", onSuccess);
      window.removeEventListener("AppleIDSignInOnFailure", onError);
    };
  });
};
