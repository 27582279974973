import * as React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { Link } from "../Link";
import { IUser } from "@fitplan/context/lib-es/auth/types";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useTatari } from "../../analytics/useTatari";
import { LogInController, LoginStrings } from "./LogInController";
import {
  Container,
  Row,
  SeparatorRow,
  Separator,
  SeparatorText,
  Input,
  Title,
  ErrorRow,
  ForgotPasswordRow,
  SignUpRow,
  AppleSignInContainer,
} from "./layout";
import { SSOButton, LoginButton } from "./Button";
import { GoogleIcon, FacebookIcon } from "./icons";
import { useTranslation } from "gatsby-plugin-react-i18next";

export interface Props {
  onComplete: (user: IUser) => void;
  darkMode?: boolean;
  redirectUri?: string;
}

export const LoginForm: React.SFC<Props> = ({
  darkMode,
  onComplete,
  redirectUri,
}) => {
  const config = useConfig();
  const { tatariIdentify, tatariTrack } = useTatari();
  const { t } = useTranslation();

  return (
    <LogInController
      onComplete={(user) => {
        tatariIdentify(user.userId);
        tatariTrack("login", { userId: user.userId });
        onComplete(user);
      }}
      redirectUri={redirectUri}
    >
      {({
        email,
        password,
        onSubmit,
        globalError,
        isLoading,
        submitDisabled,
        facebookLogin,
        googleLogin,
      }) => (
        <Container darkMode={darkMode} onSubmit={onSubmit}>
          <Row>
            <FacebookLogin
              appId={config.facebookAppId}
              autoLoad={false}
              fields="email"
              authType={"reauthenticate"}
              disableMobileRedirect={true}
              render={(props: any) => (
                <SSOButton
                  colors="dark"
                  icon={<FacebookIcon />}
                  onClick={props.onClick}
                >
                  {t("facebookLogin")}
                </SSOButton>
              )}
              {...facebookLogin}
            />
            <div className="google-login-container">
              <GoogleLogin
                shape="rectangular"
                logo_alignment="left"
                {...googleLogin}
                theme="filled_blue"
                text="signin_with"
                /* @ts-ignore */
                width={320}
              />
            </div> 
            {/* <SSOButton
              colors="light"
              icon={<GoogleIcon />}
              onClick={() => googleLoginFn()}
            >
              {t("googleLogin")}
            </SSOButton> 
            
            <GoogleLogin
              clientId={config.googleApiClientId}
              cookiePolicy={"single_host_origin"}
              render={(props) => (
                <SSOButton
                  colors="light"
                  icon={<GoogleIcon />}
                  onClick={props.onClick}
                >
                  {t("googleLogin")}
                </SSOButton>
              )}
              {...googleLogin}
            /> */}

            <AppleSignInContainer
              id="appleid-signin"
              data-color="black"
              data-border="true"
              data-type="sign in"
            />
          </Row>
          <SeparatorRow>
            <Separator />
            <SeparatorText>{t("loginSeparatorText")}</SeparatorText>
            <Separator />
          </SeparatorRow>
          <Row>
            <Input
              darkMode={darkMode}
              name="username"
              type="email"
              {...email}
            />
            <Input
              darkMode={darkMode}
              name="password"
              type="password"
              {...password}
            />
          </Row>
          <ErrorRow>{globalError}</ErrorRow>
          <LoginButton
            disabled={submitDisabled}
            type="submit"
            onClick={onSubmit}
          >
            {isLoading ? "..." : t("loginLinkText")}
          </LoginButton>
          <ForgotPasswordRow>
            <Link to="/forgot-password">{t("forgotYourPassword")}</Link>
          </ForgotPasswordRow>
        </Container>
      )}
    </LogInController>
  );
};

export type LoginFormStrings = {
  facebookButton: string;
  googleButton: string;
  loginButton: string;
  spearatorText: string;
  title: string;
  footerText: string;
  signUpLinkText: string;
  forgotPasswordLinkText: string;
} & LoginStrings;

export const LoginFooter: React.FunctionComponent<{
  onClick?: () => void;
  darkMode?: boolean;
  link?: string;
}> = ({ onClick, link = "/signup", darkMode }) => {
  const { t } = useTranslation();

  return (
    <SignUpRow darkMode={darkMode}>
      {t("dontHaveAnAccount")}
      <Link
        to={link}
        onClick={(event) => {
          if (onClick) {
            event.preventDefault();
            onClick();
          }
        }}
      >
        {t("singUpButtonText")}
      </Link>
    </SignUpRow>
  );
};
