import * as React from "react";

export interface Field {
  value: string;
  checked?: boolean;
  invalid?: boolean;
  onBlur: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onFocus: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export function validateEmail(email: string): boolean {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
