export const tatariIdentify = (userId: number) => {
  if (typeof window === "undefined" || !userId) {
    return;
  }
  (window as any)?.tatari?.identify?.(`${userId}`);
};

export const tatariTrack = (
  event: string,
  data?: number | string | { [key: string]: any }
) => {
  if (typeof window === "undefined") {
    return;
  }
  (window as any)?.tatari?.track?.(event, data);
};
