import * as React from "react";
import styled from "@emotion/styled";
import { BrandButton } from "../Button/BrandButton";

export const LoginButton = styled(BrandButton)`
  padding: 1em;
  margin: 0.5em auto;
  width: 100%;
  max-width: 20em;
`;

export interface SSOButtonProps {
  icon: React.ReactNode;
  colors: "dark" | "light";
  disabled?: boolean;
}

export const SSOButton: React.SFC<
  SSOButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = ({ icon, children, ...props }) => (
  <Container type="button" {...props}>
    <Icon>{icon}</Icon>
    <Text>{children}</Text>
  </Container>
);

const Container = styled(BrandButton)<{ colors: "dark" | "light" }>`
  display: flex;
  width: 100%;
  min-width: 15em;
  max-width: 20em;
  border-radius: 5px;
  margin: 0.5em auto;

  padding: 0.5em;
  background: ${(props) => (props.colors === "light" ? "#4285f4" : "#4267b2")};

  :hover,
  :focus,
  :active {
    background: ${(props) =>
      props.colors === "light" ? "#3275e4" : "#3257ac"};
    box-shadow: none;
  }

  :disabled {
    background: #111213;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  }
`;

const Icon = styled.span`
  flex: 0 0 1.5em;
`;

const Text = styled.span`
  flex: 1 0 4em;
  text-transform: initial;
  font-family: Barlow, sans-serif;
  font-size: 1.3em;
  font-weight: 500;
  font-stretch: condensed;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
`;
